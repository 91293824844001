import React from 'react'
import UserNameComponent from './components/UserNameComponent'
import UserImageComponent from './components/UserImageComponent'

const ProfilePage = () => {
  return (
    <div>
      <UserImageComponent/>
      <UserNameComponent/>
    </div>
  )
}

export default ProfilePage