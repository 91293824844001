import React, { useCallback, useEffect, useState } from 'react'
import { User } from '../../../interfaces/User'
import styles from './UserGridTiles.module.css'


interface Props {
        users: User[]
        handleNavigate: (receivingUserId: string) => void
}

interface Avatar extends User {
        top?: number,
        left?: number,
        color: string,
        width?: number
}

const generateAvatar = (user: User): Avatar => {
        return { ...user, color: getRandomColor() };
};

// Function to generate a random color
const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
};

const UserGridTiles: React.FC<Props> = ({ users, handleNavigate }) => {


        //const [avatars, setAvatars] = useState<Avatar[]>([])


        /*         const addAvatarWithDelay = useCallback((newAvatars: Avatar[], index: number) => {
                        if (index < newAvatars.length) {
                                setTimeout(() => {
                                        setAvatars(prevAvatars => [...prevAvatars, newAvatars[index]]);
                                        addAvatarWithDelay(newAvatars, index + 1);
                                }, 200); // 1 second delay
                        }
                }, []); */

        // Define the state for avatars, where each user id maps to a string URL
        const [avatars, setAvatars] = useState<Record<string, Avatar>>({});

        // Initialize avatars when users change
        useEffect(() => {
                const initialAvatars = users.reduce((acc: Record<string, Avatar>, user: User) => {
                        if (!avatars[user.userId]) {
                                acc[user.userId] = generateAvatar(user); // Assuming generateAvatar creates an avatar URL for a user
                        } else {
                                acc[user.userId] = avatars[user.userId]; // Keep existing avatar if already present
                        }
                        return acc;
                }, {});
                setAvatars(initialAvatars);
        }, [users]);

        // Function to update a specific user's avatar
        const updateUserAvatar = (userId: number, newAvatar: string) => {
                setAvatars(prevAvatars => ({
                        ...prevAvatars,
                        [userId]: newAvatar
                }));
        };




        return (
                <div className={`${styles.container}`}>
                        <div className={`${styles.fullGrid}`}>
                                {Object.keys(avatars)?.map(avatar => <div key={avatars[avatar]?.userId} className={`${styles.gridItem}`} style={{ backgroundColor: avatars[avatar].color, backgroundImage: `url(/images/avatars/${avatars[avatar]?.avatar || 'icons8-customer-50.png'})` }} onClick={() => handleNavigate(avatars[avatar].userId)}>

                                </div>)}

                        </div>
                </div>
        )
}

export default UserGridTiles

