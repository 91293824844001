import React, { useEffect, useState } from 'react'
import { Socket } from 'socket.io-client';
import { User } from '../interfaces/User';



interface Props {
    socket: Socket | null;
    myUserDetails: User | undefined;
}

const useUserLocation = () => {

    /* const [currentLocation, setCurrentLocation] = useState<GeolocationCoordinates | null>(null); */

/*     useEffect(() => {
        // Get current location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentLocation(position.coords);
                    if (socket) {
                        socket.emit("setLocation", { userId: myUserDetails?.userId, latitude: position.coords?.latitude, longitude: position.coords?.longitude, });
                    }
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }, [socket]); */

    async function getLocation(): Promise<{ latitude: number, longitude: number }> {
        return new Promise<{ latitude: number, longitude: number }>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    return { getLocation }
}

export default useUserLocation