import React, { useContext, useEffect } from "react";
import useGetUserList from "../../hooks/useGetUserList";
import { WebSocketContext } from "../../context/SocketProvider";
import { useNavigate } from "react-router-dom";
import { AppResponse } from "../../interfaces/AppResponse";
import styles from "./HomePage.module.css";
import UserList from "./components/UserList";
import InterestedGenderModal from "./components/interestedGenderModal/InterestedGenderModal";
import UserGrid from "./components/UserGrid";
import UserGridTiles from "./components/UserGridTiles";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faRotateRight, faSpinner } from "@fortawesome/free-solid-svg-icons";

const HomePage = () => {
    const { myUserDetails, socket } = useContext(WebSocketContext);
    const { userList, fetchUserList, isLoading, removeUser } = useGetUserList();
    const navigate = useNavigate();



/*       useEffect(() => {
        const handleBeforeUnload = (e:any) => {
          e.preventDefault();
          // Chrome requires returnValue to be set
          e.returnValue = '';
          // This will show a browser confirmation dialog
          return '';
        };
      
        window.addEventListener('beforeunload', handleBeforeUnload);
        
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
      }, []); */
    

    useEffect(() => {
        socket?.emit("user:active", { userId: myUserDetails?.userId }, (userList: any) => {
            console.log(userList);
        });
        return () => {
            socket?.emit("user:offline", { userId: myUserDetails?.userId }, (userList: any) => {
                console.log(userList);
            });
            console.log("Disconnecting-------------------------------------->")
        };
    }, [socket, myUserDetails]);

    useEffect(() => {
        const handleOnStartConversation = (res: AppResponse<{ conversationId: string }>) => {
            console.log(res);
            if (res.data?.conversationId) {
                navigate(`/chat/${res.data?.conversationId}`);
            }
        };
        if (socket) {
            socket.on("conversation:start", handleOnStartConversation);

            return () => {
                socket.off("conversation:start", handleOnStartConversation);
            };
        }
    }, [socket]);

    const handleNavigate = (receivingUserId: string) => {
        socket?.emit("conversation:start", { initializingUserId: myUserDetails?.userId, receivingUserId: receivingUserId }, (res: AppResponse<{ conversationId: string }>) => {
            if (res.success) {
                console.log(res);
                navigate(`/chat/${res.data?.conversationId}`);
            } else {
                removeUser(receivingUserId)
                toast.error(res.message);
            }
        });
        //
    };

    return (
        <>
            {isLoading ? (
                <div className="d-flex w-100 h-100 align-items-center justify-content-center"><FontAwesomeIcon icon={faCircleNotch} spin size="2x" color="white" /></div>
            ) : (
                <>
                    {userList && userList?.length > 0 ? (
                        <UserGridTiles users={userList} handleNavigate={handleNavigate} />
                    ) : (
                        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                            <div className="d-flex flex-column align-items-center">
                                <span>No users are online at the moment</span>
                                <br />
                                <div>
                                    <FontAwesomeIcon icon={faRotateRight} size="2x" color="white" style={{ cursor: "pointer" }} onClick={fetchUserList} />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            <InterestedGenderModal />
        </>
    );
};

export default HomePage;
