import React, { useEffect, useState } from 'react'
import styles from './InterestedGenderModal.module.css'
import { Modal } from 'react-bootstrap';
import Gender from '../../../../enums/Gender';

interface MyModalProps {
}

const InterestedGenderModal: React.FC<MyModalProps> = () => {
    const [modalShow, setModalShow] = useState(false);
    const [selectedGender, setSelectedGender] = useState<Gender>(Gender.none);
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    useEffect(() => {
        const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
        console.log(prefersDarkScheme);
        
        const value = localStorage.getItem('preferredGender');
        console.log(value);
        
        if (!value || !Object.values(Gender).includes(value as Gender)) {
            handleShow()
        }
    }, [])


    const handleGenderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedGender(event.target.value as Gender);
    };
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        localStorage.setItem('preferredGender', selectedGender);
        handleClose()
        // You can add more logic here, e.g., sending data to a server
    };
    return (
        <Modal className={`${styles.modal}`} show={modalShow} onHide={handleClose} centered>
            <Modal.Header className={`${styles.header}`} closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit} className={`${styles.form}`}>
                <Modal.Body>
                    <div className={`${styles.selectContainer}`}>
                        <label htmlFor="gender" className={`${styles.label} w-100`}>Looking for : </label>
                        <select name="gender" id="gender" value={selectedGender} onChange={handleGenderChange} className={`${styles.select}`}>
                            {Object.keys(Gender).map(key => <option key={key} value={key}>{key}</option>)}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${styles.footer}`}>
                    <button className={`${styles.submitBtn}`} type='submit'>Submit</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default InterestedGenderModal