import { createContext, useContext, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import SocketUtils from "../utils/SocketUtil";
import { User } from "../interfaces/User";
import SelectCoordinateTypesModal from "../components/test/selectCordinatesModal/SelectCoordinateTypesModal";
import { environment } from "../environment/environment";
import LocalStorageUtils from "../utils/LocalStoargeUtils";

export interface Coordinates {
    latitude: number;
    longitude: number;
    town: string;
}

type WebSocketContextType = {
    socket: Socket | null;
    myUserDetails: User | undefined;
    isServerConnected: boolean;
    setMyUserDetails: React.Dispatch<React.SetStateAction<User | undefined>>;
};
export const WebSocketContext = createContext<WebSocketContextType>({ socket: null, myUserDetails: undefined, isServerConnected: false, setMyUserDetails: () => { } });

export const useSocket = () => {
    return useContext(WebSocketContext);
};

const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [myUserDetails, setMyUserDetails] = useState<User>();
    const [coordinates, setCoordinates] = useState<Coordinates | undefined>({ latitude: 0, longitude: 0, town: "Colombo" });
    const [modalShow, setModalShow] = useState(false);
    const [isServerConnected, setIsServerConnected] = useState(false);

    useEffect(() => {
        console.log("isServerConnected", isServerConnected);
    }, [isServerConnected]);

    useEffect(() => {
        const handleBeforeUnload = () => {
          // Perform cleanup or save state

          socket && socket.disconnect();
          console.log("Disconnecting-------------------------------------->")
        };
      
        window.addEventListener('beforeunload', handleBeforeUnload);
      
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, [socket]);

    useEffect(() => {
        const initSocket = async () => {
            console.log("initSocket");
            console.log("coordinates", coordinates);
            if (!coordinates) {
                //handleShow()
                return;
            }
            try {
                console.log("trying");
                const myUserId = uuidv4();
                const myUserName = getUserName();
                const myAvatar = getAvatarIconName();

                const socket = io(environment.api_url, {
                   /* transports: ['websocket'], upgrade: false, */
                    query: { userId: myUserId, userName: myUserName, latitude: coordinates.latitude, longitude: coordinates.longitude, avatar: myAvatar },
                });
                setMyUserDetails({
                    userId: myUserId,
                    userName: myUserName,
                    currentlyChatWith: undefined,
                    currentLocation: { latitude: coordinates.latitude, longitude: coordinates.longitude },
                    town: coordinates.town || "",
                    avatar: myAvatar,
                });

                socket.on("connect", () => {
                    setIsServerConnected(true);
                });
                socket.on("connect_error", (error) => {
                    console.log(error.message);
                });
                socket.on("userJoined", (username) => {
                    console.log(username);
                });

                socket.on("conversationStarted", (user: User) => {
                    //setReceiverDetails(user);
                    setMyUserDetails((prevState) => {
                        if (prevState) {
                            return { ...prevState, currentlyChatWith: user?.userId };
                        }
                        return undefined;
                    });
                });

                socket.on("conversationEnded", () => {
                    // setReceiverDetails(undefined);
                    setMyUserDetails((prevState) => {
                        if (prevState) {
                            return { ...prevState, currentlyChatWith: undefined };
                        }
                        return undefined;
                    });
                });

                socket.on("receiveMessage", (message) => {
                    console.log(message);

                    // setMessages((prevState) => [...prevState, message]);
                });

                socket.on("connectedUsers", (users: User[]) => {
                    console.log(users);

                    /*   setConnectedUsers(
                                  users?.filter((user) => {
                                    return user.userId != myUserId;
                                  })
                                );
                                setNoUserAlert(true); */
                });
                setSocket(socket);
                return () => {
                    socket.disconnect();
                    console.log("Disconnecting-------------------------------------->")
                };
            } catch (error) {
                if (error instanceof Error) {
                    alert(error.message);
                }
                alert("Error occurred");
            }
        };
        initSocket();
    }, [coordinates]);
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const handleSetCoordinates = (coordinates: Coordinates) => [setCoordinates(coordinates)];
    return (
        <WebSocketContext.Provider value={{ socket, myUserDetails, isServerConnected, setMyUserDetails }}>
            <>
                {children}
                <SelectCoordinateTypesModal handleClose={handleClose} modalShow={modalShow} handleSetCoordinates={handleSetCoordinates} />
            </>
        </WebSocketContext.Provider>
    );
};

export default WebSocketProvider;

const getUserName = () => {
    const nameFromLocalStorage = LocalStorageUtils.readData<string>("name");
    if (nameFromLocalStorage) {
        return nameFromLocalStorage;
    } else {
        return SocketUtils.generateRandomUsername();
    }
};

const getAvatarIconName = () => {
    const avatarFromLocalStorage = LocalStorageUtils.readData<string>("avatar");
    if (avatarFromLocalStorage) {
        return avatarFromLocalStorage;
    } else {
        return "icons8-customer-50.png";
    }
};

async function getLongitudeAndLatitude(): Promise<{ longitude: number; latitude: number }> {
    return new Promise((resolve, reject) => {
        const longitude = prompt("Enter longitude:");
        const latitude = prompt("Enter latitude:");

        if (longitude === null || latitude === null) {
            reject("User cancelled input");
            return;
        }

        const parsedLongitude = parseFloat(longitude);
        const parsedLatitude = parseFloat(latitude);

        if (isNaN(parsedLongitude) || isNaN(parsedLatitude)) {
            reject("Invalid input. Please enter valid numeric values.");
            return;
        }

        resolve({ longitude: parsedLongitude, latitude: parsedLatitude });
    });
}
