import { Route, Routes } from "react-router-dom";
import "./App.css";
import ChatView from "./components/chat/ChatView";
import { useContext, useEffect } from "react";
import { WebSocketContext } from "./context/SocketProvider";
import HoneycombIconList from "./components/AvatarGroup";
import { ToastContainer } from "react-toastify";
import Layout from "./layouts/main/Layout";
import Loader from "./components/common/loader/Loader";
import AppLayout from "./layouts/app/AppLayout";
import HomePage from "./pages/home/HomePage";
import ProfilePage from "./pages/profile/ProfilePage";
import ChatPage from "./pages/chat/ChatPage";

function App() {
  const { isServerConnected } = useContext(WebSocketContext);

  useEffect(() => {
    console.log("isServerConnected", isServerConnected);
  }, [isServerConnected]);

  return (
    <div className="App">
      <Layout>
        {isServerConnected ? (
          <Routes>
            <Route element={<AppLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/profile" element={<ProfilePage />} />
              {/*     <Route path="/profile" element={<ProfileView />} />
              <Route path="/other" element={<OtherPageView />} />
              <Route path="/test" element={<HoneycombIconList />} /> */}
            </Route>
            <Route path="/chat/:conversationId" element={<ChatPage />} />

            <Route path="/test" element={<HoneycombIconList />} />
          </Routes>
        ) : (
          <Loader />
        )}
      </Layout>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
