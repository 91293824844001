import React from "react";
import styles from "./AppLayoutHeader.module.css";

const AppLayoutHeader = () => {
  return (
    <>
      <img className={`${styles.logo}`} src="/images/logo/findlar-logo-removebg.png" alt="" /> <span className={`${styles.text}`}>Findlar</span>
    </>
  );
};

export default AppLayoutHeader;
