namespace RandomUtils {
    const colorfulColors = [
        "#FF5733", // Vibrant Red
        "#33FF57", // Bright Green
        "#3357FF", // Vivid Blue
        "#FF33A1", // Hot Pink
        "#33FFF3", // Aqua
        "#F3FF33", // Yellow
        "#FF8333", // Orange
        "#8D33FF", // Purple
        "#FF3381", // Magenta
        "#33FF8D", // Mint Green
        "#FF5733", // Tomato Red
        "#33B5FF", // Sky Blue
        "#FF33C4", // Fuchsia
        "#57FF33", // Lime Green
        "#FF5733", // Coral
        "#3357FF", // Royal Blue
        "#FF33F3", // Electric Pink
        "#33FF83", // Spring Green
        "#F333FF", // Neon Pink
        "#FF5733", // Bright Orange
        "#33FFDA", // Turquoise
        "#FF33DA", // Deep Pink
        "#A1FF33", // Chartreuse
        "#FF5733", // Red-Orange
        "#33FF57", // Bright Green
        "#5733FF", // Indigo
        "#FF33A1", // Hot Pink
        "#33FFF3", // Aqua
        "#F3FF33", // Yellow
        "#FF8333", // Tangerine
    ];

    export function getRandomColor() {
        const randomIndex = Math.floor(Math.random() * colorfulColors.length);
        return colorfulColors[randomIndex];
    }

    export function getRandomPercentage() {
        return `${Math.floor(Math.random() * 100)}%`;
    }
    export function getRandomPosition() {
        const positions = ["center", "start", "end"];
        const randomIndex = Math.floor(Math.random() * positions.length);
        return positions[randomIndex];
    }
    export function getRandomCoordinates() {
        // List of longitude and latitude pairs for Sri Lankan cities
        const coordinates = [
            { latitude: 6.9271, longitude: 79.8612, town: "Colombo" },
            { latitude: 7.2906, longitude: 80.6234, town: "Kandy" },
            { latitude: 7.8731, longitude: 81.8463, town: "Galle" },
            { latitude: 6.9111, longitude: 79.9864, town: "Negombo" },
            { latitude: 7.2964, longitude: 80.6337, town: "Nuwara Eliya" },
            { latitude: 6.0535, longitude: 80.3860, town: "Matara" },
            { latitude: 6.6930, longitude: 81.1175, town: "Anuradhapura" },
            { latitude: 9.6615, longitude: 80.1492, town: "Jaffna" },
            { latitude: 6.0535, longitude: 79.9865, town: "Gampaha" },
            { latitude: 7.2389, longitude: 81.6923, town: "Batticaloa" },
            { latitude: 7.9800, longitude: 81.6966, town: "Hambantota" },
            { latitude: 6.3328, longitude: 80.4024, town: "Kalutara" },
            { latitude: 5.9545, longitude: 80.1184, town: "Galle" },
            { latitude: 6.3425, longitude: 79.8862, town: "Panadura" },
            { latitude: 7.0873, longitude: 79.9777, town: "Ratnapura" },
            { latitude: 9.6615, longitude: 80.9817, town: "Kilinochchi" },
            { latitude: 6.8210, longitude: 81.0656, town: "Polonnaruwa" },
            { latitude: 6.7053, longitude: 81.0587, town: "Dambulla" },
            { latitude: 6.7846, longitude: 79.8947, town: "Kaduwela" },
            { latitude: 9.8025, longitude: 80.9925, town: "Mullaitivu" }
        ];
    
        // Generate a random index
        const randomIndex = Math.floor(Math.random() * coordinates.length);
    
        // Return the randomly selected coordinates
        return coordinates[randomIndex];
    }
}

export default RandomUtils