import React, { useContext, useEffect, useState } from 'react'
import styles from './UserImageComponent.module.css'
import { Modal } from 'react-bootstrap';
import { WebSocketContext } from '../../../context/SocketProvider';
import { toast } from 'react-toastify';
import LocalStorageUtils from '../../../utils/LocalStoargeUtils';

const icons = [
    "icons8-customer-50.png",
    "icons8-dove-50.png",
    "icons8-hummingbird-50.png",
    "icons8-penguin-50.png",
    "icons8-dragon-50.png",
    "icons8-unicorn-50.png",
    "icons8-bumblebee-50.png",
    "icons8-ladybug-50.png",
    "icons8-tiger-butterfly-50.png",
    "icons8-bull-50.png",
    "icons8-pig-50.png",
    "icons8-pet-commands-train-50.png",
    "icons8-shiba-inu-50.png",
    "icons8-crab-50.png",
    "icons8-orca-50.png",
    "icons8-clown-fish-50.png",
    "icons8-red-panda-50.png",

];

const UserImageComponent = () => {
    const { myUserDetails, setMyUserDetails } = useContext(WebSocketContext)
    const [selectedIcon, setSelectedIcon] = useState<string>(myUserDetails?.avatar || icons[0]);
    const [tempSelectedIcon, setTempSelectedIcon] = useState<string>(selectedIcon);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        setSelectedIcon(myUserDetails?.avatar || icons[0])
    }, [myUserDetails])
    
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    const handleIconChange = (icon: string) => {
        setTempSelectedIcon(icon);
    };

    const handleSaveBtn = (icon: string) => {
        setSelectedIcon(icon);
        setMyUserDetails(prevState => { if (prevState) { return { ...prevState, avatar: icon } } })
        LocalStorageUtils.storeData("avatar", icon)
        toast.success("Avatar has been successfully updated");
        handleClose()
    };

    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.profilePicContainer}`}>
                <img src={`/images/avatars/${selectedIcon}`} alt="Selected Icon" />
            </div>
            <div className={`${styles.profileChangeBtnContainer}`}>
                <button onClick={handleShow}>Change avatar</button>
            </div>



            <Modal className={`${styles.modal}`} show={modalShow} centered onHide={handleClose}>
                <Modal.Header className={`${styles.header}`} closeButton>
                    <Modal.Title>Select your avatar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`${styles.selectAvatarContainer} row`}>
                        {icons.map((icon, index) => (
                            <div key={index} className={`${styles.avatarContainer} col-3 col-md-2`} onClick={() => handleIconChange(icon)}>
                                <div className={`${icon === tempSelectedIcon ? styles.active : ""}`}>
                                    <img src={`/images/avatars/${icon}`} alt={`${icon} Icon`} />
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className={`${styles.footer}`}>
                    <button onClick={() => handleSaveBtn(tempSelectedIcon)}>Save</button>
                </Modal.Footer>

            </Modal>

        </div>
    );
}

export default UserImageComponent