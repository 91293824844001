namespace SocketUtils {
    export function generateRandomUsername() {
        const adjectives = ["Happy", "Silly", "Crazy", "Lucky", "Gentle", "Brave"];
        const nouns = ["Cat", "Dog", "Bird", "Tiger", "Elephant", "Lion"];
        const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
        const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
        const randomDigits = Math.floor(Math.random() * 1000);
        return `${randomAdjective}${randomNoun}${randomDigits}`;
    }
}

export default SocketUtils