namespace LocalStorageUtils {
    export const storeData = (key: string, value: any): void => {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, serializedValue);
    };

    export const readData = <T>(key: string): T | null => {
        const serializedValue = localStorage.getItem(key);
        if (!serializedValue) return null;
        try {
            return JSON.parse(serializedValue) as T;
        } catch (error) {
            console.error("Error parsing localStorage data", error);
            return null;
        }
    };
}


export default LocalStorageUtils