import { useCallback, useContext, useEffect, useState } from "react"
import { WebSocketContext } from "../context/SocketProvider"
import { User } from "../interfaces/User"



const useGetUserList = () => {
    const [userList, setUserList] = useState<User[] | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const { myUserDetails, socket } = useContext(WebSocketContext)


    const fetchUserList = useCallback(() => {
        setIsLoading(true)
        socket?.emit("user:list", { userId: myUserDetails?.userId }, (data: any) => {
            setIsLoading(false)
            if (data && data?.userList) {
                setUserList(data?.userList);
            }

        })
    }, [socket, myUserDetails])

    const removeUser = (userId:string)=>{
        setUserList(prevState=>{
            const tempList = prevState?.filter(user=> user.userId !== userId)
            if(tempList){
                return tempList
            }
            return null
        })
    }

    useEffect(() => {
        if (socket && myUserDetails && fetchUserList) {

            fetchUserList()
        }
    }, [socket, myUserDetails, fetchUserList])




    return { userList, fetchUserList, isLoading, removeUser }
}

export default useGetUserList