import React from 'react'
import styles from './AppLayout.module.css'
import { Outlet } from 'react-router-dom';
import AppLayoutHeader from './components/AppLayoutHeader';
import AppLayoutFooter from './components/AppLayoutFooter';

const AppLayout = () => {
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.header}`}>
                <AppLayoutHeader />
            </div>
            <div className={`${styles.body}`}>
                <Outlet />
            </div>
            <div className={`${styles.footerMobile} d-flex d-md-flex`}>
                <AppLayoutFooter />
            </div>
        </div>

    )
}

export default AppLayout