import React from 'react';
import styles from './Loader.module.css';

const Loader: React.FC = () => {
    return (
        <div id={`${styles.overlay}`}>
            <div className={`${styles.spinner}`}></div>
        </div>
    );
};

export default Loader;
