import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './AppLayoutFooter.module.css'

const AppLayoutFooter = () => {
  return (
    <>
      <NavLink to="/" className={({ isActive }) =>  `${styles.navLink} ${isActive ? styles.active : ''}`}>
        {({ isActive }) => (
          <img
            src={isActive ? "/images/icons/icons8-location-50-light.png" : "/images/icons/icons8-location-50-light.png"}
            alt="Location Icon"
          />
        )}
      </NavLink>
      {/*  <div><img src="/images/icons/icons8-group-50-light.png" alt="" /></div>
      <div><img src="/images/icons/icons8-diamond-50-light.png" alt="" /></div>
      <div><img src="/images/icons/icons8-discount-50-light.png" alt="" /></div> */}
      <NavLink to="/profile"  className={({ isActive }) =>  `${styles.navLink} ${isActive ? styles.active : ''}`}>
        {({ isActive }) => (
          <img
            src={isActive ? "/images/icons/icons8-customer-50-light.png" : "/images/icons/icons8-customer-50-light.png"}
            alt="Location Icon"
          />
        )}
      </NavLink>
    </>
  )
}

export default AppLayoutFooter