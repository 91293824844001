import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import styles from './SelectCoordinateTypesModal.module.css'
import { Coordinates } from '../../../context/SocketProvider';
import RandomUtils from '../../../utils/RandomUtils';
import useUserLocation from '../../../hooks/useLocation';
import { toast } from 'react-toastify';

interface Props {
    modalShow: boolean
    handleClose: () => void
    handleSetCoordinates: (coordinates: Coordinates) => void[]
}


const SelectCoordinateTypesModal: React.FC<Props> = ({ modalShow, handleClose, handleSetCoordinates }) => {
    const { getLocation } = useUserLocation()
    const [longitude, setLongitude] = useState(0)
    const [latitude, setLatitude] = useState(0)
    const handleMyCurrentLocationBtn = async () => {
        try {

            const { latitude, longitude } = await getLocation()
            handleSetCoordinates({ latitude, longitude, town: "" })
            handleClose()
        } catch (error) {
            if (error instanceof Error) {

                toast.error(error.message)
            } else {
                toast.error("Current coordinates failed")
            }
        }
    }

    const handleRandomLocationBtn = async () => {
        const { latitude, longitude, town } = await RandomUtils.getRandomCoordinates()
        handleSetCoordinates({ latitude, longitude, town })
        handleClose()
    }

    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleSetCoordinates({ latitude, longitude, town: "" })
        handleClose()
    }


    return (
        <Modal className={`${styles.modal}`} show={modalShow} centered backdrop='static'>
            <Modal.Header className={`${styles.header}`}>
                <Modal.Title>Select Coordinates(Dev Mode. For testing only)</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={`${styles.selectContainer}`}>
                    <div onClick={handleMyCurrentLocationBtn}>
                        <button>Use My current Location</button>
                    </div>
                    <hr />
                    <div>
                        <button onClick={handleRandomLocationBtn}>Use Random Location</button>
                    </div>
                    <hr />
                    <div>
                        <p>Submit custom coordinates</p>
                        <form onSubmit={handleOnSubmit}>
                            <div>
                                <label htmlFor="longitude" className='w-100'>longitude</label>
                                <input type="text" id='longitude' name='longitude' placeholder='longitude' value={longitude} onChange={(e) => setLongitude(parseFloat(e.target.value))} />
                            </div>
                            <div>
                                <label className='w-100' htmlFor="latitude">latitude</label>
                                <input type="text" id='latitude' placeholder='latitude' value={latitude} onChange={(e) => setLatitude(parseFloat(e.target.value))} />
                            </div>
                            <button type='submit'>Submit</button>

                        </form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className={`${styles.footer}`}>
            </Modal.Footer>
        </Modal>
    )
}

export default SelectCoordinateTypesModal