import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './UserNameComponent.module.css';
import { WebSocketContext } from '../../../context/SocketProvider';
import { toast } from 'react-toastify';
import LocalStorageUtils from '../../../utils/LocalStoargeUtils';

// Define the type for form values
interface FormValues {
  name: string;
}

const UserNameComponent: React.FC = () => {
  const { myUserDetails, setMyUserDetails } = useContext(WebSocketContext)

  const formik = useFormik<FormValues>({
    initialValues: {
      name: myUserDetails?.userName || '',
    },
    enableReinitialize: true, // Add this option to reinitialize when values change
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
    }),
    onSubmit: (values) => {
      if (values.name) {
        setMyUserDetails(prevState => { if (prevState) { return { ...prevState, userName: values.name } } })
        LocalStorageUtils.storeData("name", values.name)
        toast.success("Name has been successfully updated");
      }
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        <h2>Profile</h2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputContainer}>
          <label htmlFor="name">Name*</label><br />
          <input
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className={styles.error}><small>{formik.errors.name}</small></div>
          ) : null}
        </div>
        <div className={styles.btnContainer}>
          <button type="submit">Save</button>
        </div>
      </form>
    </div>
  );
};

export default UserNameComponent;
