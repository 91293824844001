import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./ChatPage.module.css";

import { useNavigate, useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { WebSocketContext } from "../../context/SocketProvider";
import { AppResponse } from "../../interfaces/AppResponse";
import { User } from "../../interfaces/User";


interface Message {
    conversationId: string;
    sender: string;
    content: string;
    timestamp: Date;
    id: string
}

const ChatPage = () => {
    const divRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [isTyping, setIsTyping] = useState(false);
    const [conversationDetails, setConversationDetails] = useState<{ name: undefined | string, avatar: undefined | string }>({ name: undefined, avatar: undefined })
    const [showTyping, setShowTyping] = useState(false)
    const { conversationId } = useParams();
    const { myUserDetails, socket } = useContext(WebSocketContext);

    const navigate = useNavigate();



    useEffect(() => {
        return () => { handleLeaveChat() }
    }, [navigate]);


    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    useEffect(() => {
        console.log("Running");

        if (socket && conversationId) {

            socket?.emit("conversation:details", { conversationId, senderId: myUserDetails?.userId });
        }
    }, [socket, conversationId]);

    useEffect(() => {
        const handleOnEndConversation = (res: AppResponse<{ conversationId: string }>) => {
            console.log("handleOnEndConversation", res);

            navigate(`/`, { replace: true });
        };
        if (socket) {
            socket.on("conversation:end", handleOnEndConversation);

            return () => {
                socket.off("conversation:end", handleOnEndConversation);
            };
        }
    }, [socket]);

    useEffect(() => {

        const handleTyping = ({ isTyping }: { isTyping: boolean }) => {
            console.log(isTyping);
            
            setShowTyping(isTyping)
        }

        if (socket) {
            socket.on("message:typing", handleTyping);

            return () => {
                socket.off("message:typing", handleTyping);
            };
        }
    }, [socket]);

    useEffect(() => {
        const handleOnReceiveMessage = (message: Message) => {
            console.log(message);

            setMessages((prevState) => [...prevState, message]);
        };
        if (socket) {
            socket.on("message:receive", handleOnReceiveMessage);
            return () => {
                socket.off("message:receive", handleOnReceiveMessage);
            };
        }
    }, [socket]);


    useEffect(() => {
        const handleOnReceiveMessage = ({ name, avatar }: { name: string, avatar: string }) => {
            console.log("{ name, avatar }", { name, avatar });
            console.log();

            setConversationDetails({ name: name, avatar: avatar })
        };
        if (socket) {
            socket.on("conversation:details", handleOnReceiveMessage);
            return () => {
                socket.off("conversation:details", handleOnReceiveMessage);
            };
        }
    }, [socket]);

    useEffect(() => {
        console.log(`/images/icons/${conversationDetails.avatar}`);

    }, [conversationDetails])


    // Effect to detect when typing stops
    useEffect(() => {
        // Function that runs when the user stops typing
        const handleUserStoppedTyping = () => {
            console.log('User stopped typing...');
            socket?.emit("message:typing", { conversationId, isTyping: false, currentUser: myUserDetails?.userId }, (data: AppResponse<any>) => {
                console.log(data);

            });
            // You can add any other logic here
        };
        if (isTyping) {
            const timeoutId = setTimeout(() => {
                setIsTyping(false);
                handleUserStoppedTyping(); // Trigger the function when typing stops
            }, 1000); // 1 second delay after last keypress

            return () => clearTimeout(timeoutId);
        }
    }, [messages, isTyping, conversationId, socket, myUserDetails]);

    // Function that runs when the user starts typing
    const handleUserStartedTyping = () => {
        console.log('User started typing...');
        socket?.emit("message:typing", { conversationId, isTyping: true, currentUser: myUserDetails?.userId }, (data: AppResponse<any>) => {
            console.log(data);

        });
        // You can add any other logic here
    };



    const handleLeaveChat = () => {
        console.log(conversationId);

        socket?.emit("conversation:end", { conversationId });
        navigate(`/`, { replace: true });
    };
    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const messageText = formData.get("message") as string;

        if (messageText && socket && conversationId && myUserDetails?.userId) {
            const message: Message = {
                id: uuidv4(),
                conversationId: conversationId,
                sender: myUserDetails?.userId,
                content: messageText,
                timestamp: new Date(),
            };
            socket.emit("message:send", { message });
            setMessages((prevState) => [...prevState, message]);
            e.currentTarget.reset();
        }
        if (inputRef.current) {
            inputRef.current.focus()
        }
    };
    const scrollToBottom = () => {
        if (divRef.current) {
            divRef.current.scrollTo({ top: divRef.current.scrollHeight });
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {


        if (!isTyping) {
            setIsTyping(true);
            handleUserStartedTyping(); // Trigger the function when typing starts
        }
    };
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.header}`}>
                <img className={`${styles.leaveBtn}`} onClick={handleLeaveChat} src="/images/icons/icons8-back-64.png" alt="" />
                <div className={`${styles.userImageContainer}`}><img src={`/images/avatars/${conversationDetails.avatar}` || "/images/avatars/icons8-customer-50-light.png"} alt="" /></div>
                <div className={`${styles.userNameContainer}`}><h1 >{conversationDetails.name || "Guest"}</h1>
                    <span>{showTyping ? "Typing..." : "Online"}</span></div>
            </div>
            <div className={`${styles.body}`} ref={divRef}>
                {messages.map((message) => (
                    <div key={message.id} className={`${styles.message} ${message?.sender === myUserDetails?.userId ? styles.onRight : ""}`}>
                        <div className={`${styles.messageBody}`}>
                            <span>{message?.content}</span>
                        </div>
                    </div>
                ))}
                {/*       <div className={`${styles.message}`}>
                    <div className={`${styles.messageBody}`}>
                        <span>hello there</span>
                    </div>
                </div>
                <div className={`${styles.message} ${styles.onRight}`}>
                    <div className={`${styles.messageBody} `}>
                        <span>ENd hello there</span>
                    </div>
                </div> */}
                <div style={{ height: "60px" }}></div>
            </div>

            {/*     <div className={`${styles.footerDesktop} d-none d-md-block `}>
                Footer Desktop
            </div> */}
            <form onSubmit={handleFormSubmit}>
                <div className={`${styles.footerMobile}`}>
                    <div className={`${styles.subContainerFooterMobile}`}>
                        <input name="message" id="" placeholder="Message you are typing goes here" ref={inputRef} onChange={handleInputChange} />
                        <button type="submit">
                            <img src="/images/icons/icons8-send-60.png" alt="" />
                        </button>
                    </div>

                </div>
            </form>
        </div>
    );
};

export default ChatPage;
