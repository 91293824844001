import React, { useState } from 'react';
import styles from './AvatarGroup.module.css';

const HoneycombIconList: React.FC = () => {
    const [circles, setCircles] = useState([{ id: 1, top: getRandomIntInclusive(50, 450), left: getRandomIntInclusive(50, 450), color: "#fe1234", width: 70 }])

    // Function to generate a random color
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    // Function to generate a random width
    const getRandomWidth = () => {
        return Math.floor(Math.random() * 100) + 50; // Adjust range as needed
    };

    // Function to generate a random position (top/left)
    const getRandomPosition = () => {
        return {
            top: Math.floor(Math.random() * 200) + 'px',
            left: Math.floor(Math.random() * 200) + 'px',
        };
    };

    function getRandomIntInclusive(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Check if two circles overlap
    const isOverlap = (circle1: any, circle2: any) => {
        const dx = circle1.left - circle2.left;
        const dy = circle1.top - circle2.top;
        const distance = Math.sqrt(dx * dx + dy * dy);
    
        return distance < parseInt(circle1.width) / 2 + parseInt(circle2.width) / 2;
    };

    // Adjust position to prevent overlapping
    const adjustPosition: any = (newCircle: any, circles: any, attempts = 0) => {
        if (attempts > 100) {
            console.log("Max attempts reached. Unable to add circle without overlap.");
            return newCircle; // Return the circle without adjustment if maximum attempts reached
        }

        let adjustedCircle = { ...newCircle };
        let overlap = false;

        circles?.forEach((circle: any) => {
            if (isOverlap(newCircle, circle)) {
                overlap = true;
                adjustedCircle = {
                    ...adjustedCircle,
                    top: getRandomIntInclusive(50, 450),
                    left: getRandomIntInclusive(50, 450),
                };
            }
        });

        return overlap ? adjustPosition(adjustedCircle, circles,attempts + 1) : adjustedCircle;
    };


    const handleOnClick = () => {
        let tempCircles = circles

        
        for (let i = 0; i < 20; i++) {
            const newCircle: any = adjustPosition({
                id: Math.random(),
                top: getRandomIntInclusive(50, 450),
                left: getRandomIntInclusive(50, 450),
                color: getRandomColor(),
                width: 70
            }, tempCircles);
            //setCircles(prevState => [...prevState, newCircle])
            tempCircles = [...tempCircles,newCircle]
    
            
        }

        

        /*           for (let i = 0; i < 10; i++) {
                      setTimeout(() => {
                          const newCircle = adjustPosition({
                              id: Math.random(),
                              top: getRandomIntInclusive(50, 450),
                              left: getRandomIntInclusive(50, 450),
                              color: getRandomColor(),
                              width: 50
                          });
                          setCircles(prevState => [...prevState, newCircle])
                      }, i * 100); // Change the delay as needed (e.g., i * 1000 for 1 second delay)
                  } */
        tempCircles.map((item, i) => {
            setTimeout(() => {

                setCircles(prevState => [...prevState, item])
            }, i * 100); // Change the delay as needed (e.g., i * 1000 for 1 second delay)
        })
    }

    /*     const handleOnClick = () => {
    
    
            const newCircle = adjustPosition({
                id: Math.random(),
                top: getRandomIntInclusive(50, 450),
                left: getRandomIntInclusive(50, 450),
                color: getRandomColor(),
                width: 50
            });
            setCircles(prevState => [...prevState, newCircle])
    
        }  */

    return (
        <div>

            <button onClick={handleOnClick}>Add circle</button>
            <div className={styles.container}>
                {circles?.map(circle => <div
                    key={circle.id}
                    className={`${styles.circle}`}
                    style={{
                        width: `${circle.width}px`,
                        backgroundColor: circle.color,
                        top: circle.top,
                        left: circle.left
                    }}
                ></div>)}
            </div>
        </div>
    );
};

export default HoneycombIconList;
